/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../data-access/authentication.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

    constructor(private readonly _authService: AuthenticationService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const shouldSkipHeaders = request.headers?.get('skip') !== null;

        if (shouldSkipHeaders) {
            request = request.clone({headers: request.headers.delete('skip')});
            return next.handle(request);
        }

        const defaultHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Authorization', `Bearer ${this._authService.getCachedAccessToken()}`);

        request = request.clone({
            headers: defaultHeaders,
        });

        return next.handle(request);
    }
}