import {NgModule} from '@angular/core';
import {PreloadAllModules, Route, RouterModule,} from '@angular/router';
import {AuthGuard} from '@auth0/auth0-angular';
import {RolesGuard} from './shared/guards/rolesGuard';
import { Roles } from './shared/enum/enums';
import { LearningCatalogTitle } from './shared/constants/constants';

export const appRoutes: Route[] = [
    {
        path: '',
        title: LearningCatalogTitle,
        canActivate: [AuthGuard, RolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member]
        },
        loadChildren: () => import('./pages/learning-catalog/learning-catalog.module').then(m => m.LearningCatalogModule),
    },
    {
        path: 'courses/:journeyId/:journeySlug/:lessonId/:lessonSlug',
        canActivate: [AuthGuard, RolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member],
            isAdminPage: false
        },
        loadChildren: () => import('./pages/learning-content/learning-content.module').then(m => m.LearningContentModule),
    },
    {
        path: 'courses/:journeyId/:journeySlug',
        canActivate: [AuthGuard, RolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member]
        },
        loadChildren: () => import('./pages/journey-overview/journey-overview.module').then(m => m.JourneyOverviewModule),
    },
    {
        path: 'admin/journey-preview',
        canActivate: [AuthGuard, RolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor],
            isAdminPage: true
        },
        loadChildren: () => import('./pages/journey-overview/journey-overview.module').then(m => m.JourneyOverviewModule),
    },
    {
        path: 'admin/journey-preview/:journeyId/:journeySlug',
        canActivate: [AuthGuard, RolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor],
            isAdminPage: true
        },
        loadChildren: () => import('./pages/learning-content/learning-content.module').then(m => m.LearningContentModule),
    },
    {
        path: 'admin',
        canActivate: [AuthGuard, RolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor],
            isAdminPage: true,
            isAdminLessonPreviewPage: true
        },
        loadChildren: () => import('./pages/learning-content/learning-content.module').then(m => m.LearningContentModule),
    },
    {
        path: 'error',
        canActivate: [AuthGuard, RolesGuard],
        data: {
            roles: [Roles.SuperAdmin, Roles.ContentEditor, Roles.Member],
            isAdminPage: false
        },
        loadChildren: () => import('./pages/error-pages/error-pages.module').then(m => m.ErrorPagesModule),
    },
    {
        path: '**',
        redirectTo: 'error/not-found',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, {
            preloadingStrategy: PreloadAllModules,            
            bindToComponentInputs: true,
            scrollPositionRestoration: 'top'
        }),
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}