import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app.routes';
import {AuthModule} from '@auth0/auth0-angular';
import environment from '../environments/environment';
import {AuthorizationInterceptor} from './shared/interceptors/authorization-interceptor';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderService} from './shared/data-access/translation-loader.service';
import {ActionReducer, MetaReducer, StoreModule} from '@ngrx/store';
import {loadingReducer} from './shared/data-access/store/loading/loading.reducer';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {localStorageSync} from 'ngrx-store-localstorage';
import {learningCatalogStoreKey, loadingStoreKey, rolesStoreKey} from './shared/constants/store-constants';
import {rolesReducer} from './shared/data-access/store/roles/roles.reducer';
import {OverlayModalComponent} from './shared/feature/overlay-modal/overlay-modal.component';
import {CacheControlInterceptor} from './shared/interceptors/cache-control-interceptor';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ErrorsInterceptor} from './shared/interceptors/errors-interceptor';
import { GlobalErrorHandler } from './shared/error-handler/global-error-handler';

//We want to merge the local storage state with the current state. We want to keep the filters from the local storage and the rest of the state from the current state.
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
    return localStorageSync({keys: [learningCatalogStoreKey]})(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

const {defaultConfiguration} = environment;

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        AuthModule.forRoot({
            cacheLocation: 'localstorage',
            domain: defaultConfiguration.auth.domain,
            clientId: defaultConfiguration.auth.clientId,
            authorizationParams: {
                responseType: 'token',
                scope: 'openId profile email offline_access',
                audience: defaultConfiguration.auth.audience,
                redirect_uri: defaultConfiguration.auth.appUri,
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: TranslationLoaderService,
                deps: [HttpClient],
            },
        }),
        OverlayModalComponent,
        StoreModule.forRoot({
            [rolesStoreKey]: rolesReducer,
            [loadingStoreKey]: loadingReducer
        },
        { metaReducers }),
        EffectsModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.defaultConfiguration.production
        })
    ],
    declarations: [
        AppComponent
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorsInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheControlInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {
}