/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import { ErrorHandler, Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import environment from '../../../environments/environment';
import { ENVIRONMENTS } from '../constants/environments-constants';
const { auth } = environment.defaultConfiguration;

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  handleError(error: Error): void {

    datadogRum.addError(error);

    if (!this.isProduction()) {
      console.error('An unexpected error occurred:', error);
    }

    throw error;
  }

  private isProduction(): boolean {
    return auth.envName === ENVIRONMENTS.PRODUCTION;
  }
}
