/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

// This interceptor is used to prevent caching of video and typeform requests because they are rendered in iframes.
@Injectable()
export class CacheControlInterceptor implements HttpInterceptor {
    // Array of URL patterns that need to have cache headers set.
    private readonly cacheControlUrls: string[] = ['video', 'typeform'];

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.shouldSetCacheControlHeaders(request.url)) {
            const headers = request.headers
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache')
                .set('Expires', '0');

            const clonedRequest = request.clone({headers});
            return next.handle(clonedRequest);
        }

        return next.handle(request);
    }

    private shouldSetCacheControlHeaders(url: string): boolean {
        return this.cacheControlUrls.some(pattern => url.includes(pattern));
    }
}
