import {Injectable} from '@angular/core';
import {datadogRum} from '@datadog/browser-rum';
import environment from '../../../environments/environment';
import { ENVIRONMENTS } from '../constants/environments-constants';

@Injectable({
    providedIn: 'root'
})
export class DatadogService {

    public initiate(): void {
        const { auth, production, applicationVersion } = environment.defaultConfiguration;
        const shouldInitiateDatadog = [ENVIRONMENTS.QA, ENVIRONMENTS.E2E, ENVIRONMENTS.CICD].includes(auth.envName) || production;
        const excludedErrorMessages = ['CanceledError', 'Beacon', 'csp_violation'];

       if (shouldInitiateDatadog) {
            datadogRum.init({
                applicationId: 'a6b38c32-64ff-4590-b6e7-7dd60f229921',
                clientToken: 'pubc75f34cdde5743d536f52ff63cc1c353',
                site: 'datadoghq.eu',
                service: 'aihr-lms-spa',
                env: auth.envName,
                version: applicationVersion,
                sessionSampleRate: 100,
                sessionReplaySampleRate: 100,
                trackUserInteractions: true,
                trackResources: true,
                trackLongTasks: true,
                trackFrustrations: true,
                defaultPrivacyLevel: 'allow',
                useCrossSiteSessionCookie: true,
                trackSessionAcrossSubdomains: true,
                telemetrySampleRate: 100,
                beforeSend: event => {
                    if (event.type === 'error') {
                        const errorMessage = event.error?.message || '';

                        if (excludedErrorMessages.some(excludedMessage => errorMessage.includes(excludedMessage))) {
                            return false; // Prevent the event from being logged
                        }
                    }
                    
                    return true
                },
            });

            datadogRum.startSessionReplayRecording();
        }
    }
}