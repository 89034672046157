import { createReducer, on } from '@ngrx/store';
import {setRoles} from './roles.actions';

export interface IRolesState {
    roles: string[];
}

export const initialState: IRolesState = {
    roles: []
};


export const rolesReducer = createReducer(
    initialState,
    on(setRoles, (state, {roles}) => ({...state, roles: roles})),
);

