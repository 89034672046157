/*
 * Copyright (C) 2024 AIHR
 * License EULA
 *
 * This software and its contents are the property of [AIHR].
 * Unauthorized copying of this file, via any medium, is strictly prohibited.
 * Proprietary and confidential.
 */

import {Injectable} from '@angular/core';
import environment from '../../../environments/environment';
import {initialize, LDClient, LDContext, LDFlagValue} from 'launchdarkly-js-client-sdk';
import {map, Observable, Subject} from 'rxjs';
import {AuthenticationService} from './authentication.service';

@Injectable({
    providedIn: 'root'
})
export class FeatureFlagService {
    private client: LDClient;

    constructor(private readonly _authenticationService: AuthenticationService) {
    }

    public initialize(): void {
        this._authenticationService.user$.subscribe(user => {
            const context: LDContext = {
                kind: 'user',
                key: environment.defaultConfiguration?.auth.launchDarklyUserId,
                user: user ?? {}
            };
            if (!this.client) {
                this.client = initialize(
                    environment.defaultConfiguration?.auth.launchDarklyClientId,
                    context
                );
            }
        });
    }

    public getFlag(flagKey: string, defaultValue: LDFlagValue = false): Observable<LDFlagValue> {
        if (!this.client) {
            return new Observable(defaultValue);
        }
        const fetchFlag = new Subject<void>();
        this.client.on(`change:${flagKey}`, () => {
            fetchFlag.next();
        });
        this.client.waitUntilReady().then(() => {
            fetchFlag.next();
        });
        return fetchFlag.pipe(map(() => this.client.variation(flagKey, defaultValue)));
    }

    public destroy(): void {
        this.client?.flush(() => {
            this.client.close();
        });
    }
}
