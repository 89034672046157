import {Injectable} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {first, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SidebarService {
    public isMenuOpen = false;
    public isSmallScreen: boolean;
    public isSmallScreenSubject: Subject<boolean> = new Subject<boolean>();
    public onOpenSubject: Subject<void> = new Subject<void>();

    private sideBarMenu: MatSidenav;

    public closeSidebar(sideNavBar?: MatSidenav): void {
        if (sideNavBar) {
            this.sideBarMenu = sideNavBar;
            sideNavBar.close();
            this.isMenuOpen = false;
        }
    }

    public openSidebar(sideNavBar?: MatSidenav): void {
        if (sideNavBar) {
            this.sideBarMenu = sideNavBar;
            sideNavBar.open();
            this.isMenuOpen = true;
        }
    }

    public toggleSidebar(sideNavBar?: MatSidenav): void {
        if (sideNavBar) {
            this.sideBarMenu = sideNavBar;
        }
        this.sideBarMenu.openedChange.pipe(first()).subscribe(() => {
            this.onOpenSubject.next();
        });
        this.sideBarMenu.toggle();
        this.isMenuOpen = !this.isMenuOpen;
    }
}
