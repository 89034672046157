import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router} from '@angular/router';
import {AuthenticationService} from '../data-access/authentication.service';
import {combineLatest, first, Observable, Subscriber} from 'rxjs';
import environment from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {LocalStorageService} from '../data-access/storage.service';
import { ENVIRONMENTS } from '../constants/environments-constants';
import { Roles } from '../enum/enums';

@Injectable({
    providedIn: 'root'
})
export class RolesService {

    constructor(
        private readonly _authenticationService: AuthenticationService,
        private readonly _router: Router,
        private readonly _localStorageService: LocalStorageService,
        private readonly _translateService: TranslateService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
        return new Observable<boolean>(observer => {
                this._authenticationService.isUserAuthenticated().pipe(first()).subscribe({
                next: (isAuthenticated) => {
                    if (!isAuthenticated) {
                        this._authenticationService.logout();
                        observer.next(false);
                        observer.complete();
                        return;
                    }

                    this.checkUserAccessAndRoles(observer, route);
                },
                error: () => {
                    this._authenticationService.logout();
                    observer.next(false);
                    observer.complete();
                }
            });
        });
    }

    private redirectToAccountManagement(): void {
        this._localStorageService.clear();
        window.location.href = `${environment.defaultConfiguration.auth.accountManagementAppUrl}/main/account-management/account-details`;
    }

    private checkUserAccessAndRoles(observer: Subscriber<boolean>, route: ActivatedRouteSnapshot): void {
        combineLatest([
            this._translateService.get('accessEmails'),
            this._authenticationService.user$
        ]).pipe(first()).subscribe(([emails, user]) => {
            //TODO: Remove this check we fully migrate all users
            if(environment.defaultConfiguration.auth.envName !== ENVIRONMENTS.CICD && 
                !emails.includes(user.email) && !user.email.includes('@aihr.com') && !user['AIHR/Roles'].includes(Roles[Roles.Migrated])){
                this.redirectToAccountManagement();
                return;
            }

            this._authenticationService.userHasRequiredRole(route.data.roles).pipe(first()).subscribe(hasRequiredRole => {
                if (!hasRequiredRole && !this._authenticationService.isMember) {
                    this.redirectToAccountManagement();
                    return;
                }

                if (!hasRequiredRole) {
                    this._router.navigate(['/courses']);
                    observer.next(false);
                    observer.complete();
                    return;
                }

                observer.next(true);
                observer.complete();
            });
        });
    }
}


export const RolesGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
    return inject(RolesService).canActivate(route);
}