import { createReducer, on } from '@ngrx/store';
import {startLoading, stopLoading} from './loading.actions';

export interface ILoadingState {
    isLoading: boolean;
}

export const initialState: ILoadingState = {
    isLoading: false
}

export const loadingReducer = createReducer(
    initialState,
    on(startLoading, (state) => ({...state, isLoading: true})),
    on(stopLoading, (state) => ({...state, isLoading: false})),
);
