import { Component, Inject, Input, Optional } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';
import { NotificationType } from '../../enum/enums';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

export interface NotificationCardInput {
    title: string;
    description: string;
    type: NotificationType;
    shouldShowCloseButton: boolean;
}

@Component({
    standalone: true,
    selector: 'app-notification-card',
    templateUrl: './notification-card.component.html',
    styleUrls: ['./notification-card.component.scss'],
    imports: [CommonModule, TranslateModule]
})
export class NotificationCardComponent {
    @Input() public shouldShowCloseButton: boolean = true;
    @Input() public type: NotificationType = NotificationType.Warning;
    @Input() public title: string;
    @Input() public description: string;

    constructor(
        @Optional() @Inject(MAT_SNACK_BAR_DATA) public data: NotificationCardInput,
        private _snackBarRef: MatSnackBar
    ) {
        if (data) {
            const { type, title, description, shouldShowCloseButton } = data;
            this.type = type;
            this.title = title;
            this.description = description;
            this.shouldShowCloseButton = shouldShowCloseButton ?? true;
        }
    }

    public close(): void {
        this._snackBarRef.dismiss();
    }
}